<template>
  <div>
    <input type="text" style="display:none" />
    <input type="password" style="display:none" />
    <Navbar :hideSearch="true" />
    <div class="app-body my-5 py-5" style="min-height:60vh">
      <p class="text-subtitle bold">Update Profile Picture</p>
      <hr />
      <div class="columns">
        <div class="column">
          <b-field label="Current Profile Picture"></b-field>
          <img
            v-if="this.$store.state.userDetail"
            :src="this.$store.state.userDetail.profilePicture"
            alt=""
            width="50%"
          />
        </div>
        <div class="column ">
          <b-field label="New Profile Picture">
            <b-upload v-model="profilePictureFile" multiple drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"> </b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <span
              v-for="(file, index) in profilePictureFile"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile(index)"
              ></button>
            </span>
          </div>
          <b-button
            :loading="isLoading"
            type="is-success"
            class="mt-2"
            @click="changeProfilePicture"
            >Update Profile Picture</b-button
          >
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import api from "@/services/dataService";
import Notification from "@/services/notificationService";

export default {
  name: "MyIssuances",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      profilePictureFile: [],
      issuances: undefined,
      isLoading: false,
      password: {
        current: "",
        new: "",
        confirm: ""
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    deleteDropFile(index) {
      this.profilePictureFile.splice(index, 1);
    },
    async changeProfilePicture() {
      this.isLoading = true;
      const uploadRequest = new FormData();
      uploadRequest.append("coverPhoto", this.profilePictureFile[0]);
      uploadRequest.append("resourceId", this.$store.state.currentUser.id);
      uploadRequest.append("resourceType", "User");
      if (this.profilePictureFile[0]) {
        await api
          .post("/common/s3/profile-picture", uploadRequest, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            Notification.success(
              "Profile Picture has been successfully changed"
            );
            this.$router.go();
          })
          .catch(err => {
            Notification.error(
              Array.isArray(err.message) ? err.message[0] : err.message.msg
            );
          });
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
